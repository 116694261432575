@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.door-opener-root {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
}

header {
  background: #2e94d3;
  text-align: left;
  padding: 10px;
}
header .logo {
  width: 50%;
  max-width: 300px;
}

.box {
  background: #6cb4e0;
  border-radius: 10px;
  padding: 25px 35px 35px 35px;
  position: relative;
  margin: 25px;
}
.box .icon {
  position: absolute;
  top: 15px;
  left: 10px;
  max-width: 40px;
  max-height: 25px;
}
.box .locationText {
  text-align: center;
  color: #fff;
  font-family: "Lato";
  font-weight: 900;
  font-size: 22px;
}
.box .hint {
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  font-family: "Lato";
  font-weight: 600;
  font-size: 16px;
  margin: 10px 0 20px 0;
}
.box .door-opener-swipe {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.5);
  height: 36px;
  border-radius: 18px;
}
.box .door-opener-swipe .MuiSlider-root {
  height: 36px;
  display: block;
  width: calc(100% - 36px);
  padding: 0;
}
.box .door-opener-swipe .MuiSlider-thumb {
  width: 36px;
  height: 36px;
  background: #356ca0;
  margin-top: 0;
  margin-left: 0;
}
.box .door-opener-swipe .MuiSlider-rail,
.box .door-opener-swipe .MuiSlider-track {
  display: none;
}

.curtain {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  text-align: center;
}
.modal {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  margin: 80px 25px 0 25px;
  max-width: 350px;
  display: inline-block;
}
.modal img {
  display: block;
  width: 100%;
}
.no-location {
  text-align: Center;
  padding: 25px;
}
.no-location img {
  max-width: 25%;
  margin: 20px;
}
.no-location > div {
  text-align: center;
  color: #666666;
  font-family: "Lato";
  font-weight: 900;
  font-size: 20px;
}
.loader {
  margin-top: 100px;
}

